<template>
  <div class="trainers" v-if="trainers">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <DataTable
      class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
      ref="trainersPaginatedTable"
      :value="trainers"
      dataKey="number"
      :lazy="true"
      :loading="isLoading"
      :totalRecords="getTrainerCount"
      :paginator="true"
      :rows.sync="tableState.pagination.rowsPerPage"
      :first="pageOffset"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :currentPageReportTemplate="
        $t('show') +
          ' {first} ' +
          $t('to') +
          ' {last} ' +
          $t('of') +
          ' {totalRecords} ' +
          $t('records')
      "
      :rowsPerPageOptions="[10, 15, 20, 25, 30, 40, 50, 100, 200]"
      :filters.sync="tableState.filters"
      stateStorage="local"
      :stateKey="tableState.filterName"
      filterDisplay="row"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      @state-restore="onStateRestore($event)"
      :rowHover="true"
      :sortField="tableState.sortField"
      :sortOrder="tableState.sortOrder"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header">
          <div class="table-header-left">
            <h4 class="page-title">{{ $t('trainers') }}</h4>
          </div>
          <div class="table-header-right">
            <button class="btn btn-sm btn-inverse" @click.prevent="onRemoveFilters()">
              <i class="fa fa-remove mr-2"></i>
              {{ $t('remove_filters') }}
            </button>

            <router-link class="btn btn-sm btn-inverse" :to="{ name: 'TrainerCreatePage' }">
              <i class="fa fa-plus-circle mr-2"></i>
              {{ $t('create') }}
            </router-link>
          </div>
        </div>
      </template>

      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>

      <Column
        v-if="isAdmin"
        field="number"
        header="#"
        sortable
        :showFilterMenu="false"
        :headerStyle="{ width: '60px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <router-link
            v-if="slotProps.data.number"
            :to="{
              name: 'TrainerEditPage',
              params: { trainerNumber: slotProps.data.number },
            }"
            >{{ slotProps.data.number.toString().padStart(4, '0') }}</router-link
          >
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="lastname" :header="$t('lastname')" sortable>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="firstname" :header="$t('firstname')" sortable>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="users" :header="$t('users')" sortable>
        <template #body="slotProps">
          <ul>
            <li v-for="(user, index) in slotProps.data.users" :key="index">
              {{ user.firstname }} {{ user.lastname }} - {{ user.email }}
            </li>
          </ul>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';
import Dropdown from 'primevue/dropdown';

export default {
  components: {
    DataTable,
    Breadcrumbs,
    Column,
    InputText,
    // Dropdown,
  },
  data() {
    return {
      loading: false,
      filterName: 'trainers-table-filters',
      defaultSortField: 'number',
      defaultSortOrder: -1,
      defaultFilters: {
        number: { value: null, matchMode: FilterMatchMode.EQUALS },
        lastname: { value: null, matchMode: FilterMatchMode.CONTAINS },
        firstname: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      tableState: {
        pagination: {
          page: 0,
          rowsPerPage: 10,
        },
        sortField: this.defaultSortField,
        sortOrder: this.defaultSortOrder,
        filters: this.defaultFilters,
        filterName: 'trainers-table-filters',
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters(['isLoading', 'getTrainers', 'getTrainerCount', 'getClients', 'getEnumValues']),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('trainers') }];
    },
    trainers() {
      return this.getTrainers;
    },
    pageOffset() {
      return this.tableState.pagination.page * this.tableState.pagination.rowsPerPage;
    },
  },
  methods: {
    ...mapActions([
      'fetchTrainers',
      'fetchTrainersByClient',
      'fetchEmployers',
      'fetchEnumValues',
      'fetchUserByNumber',
      'fetchUserAttachmentUrl',
    ]),
    ...mapActions('auth', ['refreshUser']),

    /**
     * Load remote table data
     */
    async loadTableData() {
      if (this.isClient) {
        await this.fetchTrainersByClient({
          clientId: this.getCurrentUser.client.id,
          page: this.pageOffset,
          pageSize: this.tableState.pagination.rowsPerPage,
          sortField: this.tableState.sortField,
          sortOrder: this.tableState.sortOrder,
          filters: { ...this.tableState.filters },
        });
      } else if (this.isAdmin) {
        await this.fetchTrainers({
          page: this.pageOffset,
          pageSize: this.tableState.pagination.rowsPerPage,
          sortField: this.tableState.sortField,
          sortOrder: this.tableState.sortOrder,
          filters: { ...this.tableState.filters },
        });
      }
    },

    /**
     * Load more results from server or cache on pagination click
     */
    async onPage(event) {
      this.tableState.pagination.page = event.page;
      this.tableState.pagination.rowsPerPage = event.rows;
      this.loadTableData();
    },
    /**
     * Load results from server/cache on sort
     */
    async onSort(event) {
      this.tableState.sortField = event.sortField;
      this.tableState.sortOrder = event.sortOrder;
      this.loadTableData();
    },
    /**
     * Load results from server/cache on filter
     */
    async onFilter(event) {
      this.loadTableData();
    },
    async onRemoveFilters(event) {
      this.tableState.sortOrder = this.defaultSortOrder;
      this.tableState.sortField = this.defaultSortField;
      this.tableState.filters = this.defaultFilters;
      this.tableState.pagination.page = 0;
      this.tableState.pagination.rowsPerPage = 20;
      localStorage.removeItem(this.filterName);
      await this.loadTableData();
    },
    async onStateRestore(event) {
      this.setupFilters();

      if (this.tableState) {
        this.tableState.sortField = event.sortField ? event.sortField : this.defaultSortField;
        this.tableState.sortOrder = event.sortOrder ? event.sortOrder : this.defaultSortOrder;
        this.tableState.pagination.page = 0; // event.first / event.rows;
        this.tableState.pagination.rowsPerPage = event.rows;
        this.tableState.pagination.rowsPerPage = event.rows;
      }
    },

    setupFilters() {
      this.tableState.filters = this.tableState.filters
        ? this.tableState.filters
        : this.defaultFilters;
      this.tableState.pagination.page = 0;
      this.tableState.pagination.rowsPerPage = 15;
      this.tableState.sortField = this.tableState.sortField
        ? this.tableState.sortField
        : this.defaultSortField;

      this.tableState.sortOrder = this.tableState.sortOrder
        ? this.tableState.sortOrder
        : this.defaultSortOrder;
    },
  },
  async created() {
    this.setupFilters();
  },
  async mounted() {
    this.loading = false;
    await this.loadTableData();
  },
};
</script>

<style lang="scss">
.p-column-filter-menu-button {
  display: none;
}
.p-column-filter-clear-button {
  display: none;
}
.table-header {
  display: flex;
  justify-content: space-between;
}
.table-header-left {
  display: flex;
  justify-content: flex-start;
}
.table-header-right {
  display: flex;
  justify-content: flex-end;
  column-gap: 1em;
}

.p-calendar .p-datepicker {
  min-width: 100%;
  width: 29em;
}

/** Fix Dropdown overlay */
.p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: 37rem;
}
</style>
