var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.trainers)?_c('div',{staticClass:"trainers"},[_c('Breadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),_c('DataTable',{ref:"trainersPaginatedTable",staticClass:"p-datatable-sm p-datatable-striped p-datatable-gridlines",attrs:{"value":_vm.trainers,"dataKey":"number","lazy":true,"loading":_vm.isLoading,"totalRecords":_vm.getTrainerCount,"paginator":true,"rows":_vm.tableState.pagination.rowsPerPage,"first":_vm.pageOffset,"paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","currentPageReportTemplate":_vm.$t('show') +
        ' {first} ' +
        _vm.$t('to') +
        ' {last} ' +
        _vm.$t('of') +
        ' {totalRecords} ' +
        _vm.$t('records'),"rowsPerPageOptions":[10, 15, 20, 25, 30, 40, 50, 100, 200],"filters":_vm.tableState.filters,"stateStorage":"local","stateKey":_vm.tableState.filterName,"filterDisplay":"row","rowHover":true,"sortField":_vm.tableState.sortField,"sortOrder":_vm.tableState.sortOrder,"responsiveLayout":"scroll"},on:{"update:rows":function($event){return _vm.$set(_vm.tableState.pagination, "rowsPerPage", $event)},"update:filters":function($event){return _vm.$set(_vm.tableState, "filters", $event)},"page":function($event){return _vm.onPage($event)},"sort":function($event){return _vm.onSort($event)},"filter":function($event){return _vm.onFilter($event)},"state-restore":function($event){return _vm.onStateRestore($event)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"table-header"},[_c('div',{staticClass:"table-header-left"},[_c('h4',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.$t('trainers')))])]),_c('div',{staticClass:"table-header-right"},[_c('button',{staticClass:"btn btn-sm btn-inverse",on:{"click":function($event){$event.preventDefault();return _vm.onRemoveFilters()}}},[_c('i',{staticClass:"fa fa-remove mr-2"}),_vm._v(" "+_vm._s(_vm.$t('remove_filters'))+" ")]),_c('router-link',{staticClass:"btn btn-sm btn-inverse",attrs:{"to":{ name: 'TrainerCreatePage' }}},[_c('i',{staticClass:"fa fa-plus-circle mr-2"}),_vm._v(" "+_vm._s(_vm.$t('create'))+" ")])],1)])]},proxy:true},{key:"empty",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('no_data_found'))+" ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('loading'))+" ")]},proxy:true}],null,false,2426209058)},[(_vm.isAdmin)?_c('Column',{attrs:{"field":"number","header":"#","sortable":"","showFilterMenu":false,"headerStyle":{ width: '60px' },"bodyStyle":{ 'text-align': 'center' }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(slotProps.data.number)?_c('router-link',{attrs:{"to":{
            name: 'TrainerEditPage',
            params: { trainerNumber: slotProps.data.number },
          }}},[_vm._v(_vm._s(slotProps.data.number.toString().padStart(4, '0')))]):_vm._e()]}},{key:"filter",fn:function({filterModel,filterCallback}){return [_c('InputText',{attrs:{"type":"text"},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}],null,false,3700110801)}):_vm._e(),_c('Column',{attrs:{"field":"lastname","header":_vm.$t('lastname'),"sortable":""},scopedSlots:_vm._u([{key:"filter",fn:function({filterModel,filterCallback}){return [_c('InputText',{attrs:{"type":"text"},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}],null,false,4046805386)}),_c('Column',{attrs:{"field":"firstname","header":_vm.$t('firstname'),"sortable":""},scopedSlots:_vm._u([{key:"filter",fn:function({filterModel,filterCallback}){return [_c('InputText',{attrs:{"type":"text"},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}],null,false,4046805386)}),_c('Column',{attrs:{"field":"users","header":_vm.$t('users'),"sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('ul',_vm._l((slotProps.data.users),function(user,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(user.firstname)+" "+_vm._s(user.lastname)+" - "+_vm._s(user.email)+" ")])}),0)]}}],null,false,3071411932)})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }